/* App.css or your global CSS file */

@font-face {
  font-family: 'OldEnglishGothicPixelRegular';
  src: url('./fonts/OldEnglishGothicPixelRegular-gx1jp.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pixelon';
  src: url('./fonts/Pixelon-OGALo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  background-color: black;
  letter-spacing: 0.25em;
  font-family: 'OldEnglishGothicPixelRegular', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  color: #ff0000;
  text-shadow: 0px 0px 20px rgba(255, 0, 0, 0.8);
  font-size: 2em;
  font-weight: normal;
  text-align: center;
}

button {
  font-family: 'OldEnglishGothicPixelRegular', sans-serif;
  border: none;
  background-color: black;
  color: #ff0000;
  cursor: pointer;
  font-size: 2em;
  text-shadow: 0px 0px 20px rgba(255, 0, 0, 0.8);
}

button:hover {
  background-color: rgb(255, 0, 0);
  color: black;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 6em;
}

.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 2em;
  margin-top: -20px;
}

.password-title {
  font-size: 2em;
  text-shadow: 0px 0px 20px rgba(255, 0, 0, 0.8);
}

.password-input {
  display: inline-block;
  height: fit-content;
  font-size: 24px;
  margin-top: 20px;
  border: none;
  outline: none;
  background: none;
  caret-color: red;
  color: rgb(255, 0, 0);
  padding: 0;
  min-width: 30px;
  white-space: nowrap;
  font-family: 'OldEnglishGothicPixelRegular', sans-serif;
  text-align: center;
  font-size: 6em;
  text-shadow: 0px 0px 20px rgba(255, 0, 0, 0.8);
}

.bar-box {
  background-color: transparent;
  width: 30vw;
  height: 1em;
  border: 4px solid #ff0000;
  display: flex;
  align-items: center;
  padding: 6px;
  box-shadow: 0px 0px 20px rgba(255, 0, 0, 0.4);
}

.border-fill {
  background-color: rgb(255, 0, 0);
  height: 100%;
  width: 0;
  animation: fill 10s linear forwards;
}

.animated-video {
  height: 100vh;  /* Full viewport height */
  width: 100vw;   /* Full viewport width */
  object-fit: cover; /* Ensures the video covers the area without distortion */
}

.play{
  text-align: center;
}



@keyframes fill {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.back-button {
  position: absolute;
  top: 2em;
  left: 2em;
}

p {
  color: rgb(0, 0, 0);
  background-color: #ff0000;
  font-size: 1em;
  padding: 4px;
  font-family: 'Pixelon';
  letter-spacing: 0;
  font-weight: bold;
}

.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.animated-image {
  max-width: 100%;
  max-height: 100%;
  transition: opacity 0.1s ease-in-out;

}

.svg-guy {
  width: 4em;
  transition: transform 0.3s ease; /* Smooth transition for movement */
}

/* Apply the hover effect on the parent .svg-button */
.svg-button:hover .svg-guy {
  transform: translateY(-10px); /* Move up on hover */
}


/* Media Query for small screens (max-width: 768px) */
@media (max-width: 768px) {
  .password-input {
    font-size: 5em;
  }

  .password-title {
    font-size: 2em;
  }

  .back-button {
    position: absolute;
    top: 1em;
    left: 1em;
  }

  .bar-box {
    width: auto;
  }

  .bar {
    width: 100%;
  }

  h1 {
    display: flex;
    align-items: center;
  }

  .animated-image {
    height: calc(100vh - 20px);
    width: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the screen while maintaining aspect ratio */
  }


  body {
    background-color: black;
    letter-spacing: 0.25em;
    font-family: 'OldEnglishGothicPixelRegular', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px; /* Adds space at the top */
}

.svg-guy {
  width: 4em;
  transition: transform 0.3s ease; /* Smooth transition for movement */
  
}

.back-button {
  position: absolute;
  left: 1em;
}



 
}
